import { inject, DestroyRef } from '@angular/core';
import { ReplaySubject, takeUntil } from 'rxjs';

export const untilDestroyed = () => {
  const replaySubject = new ReplaySubject(1);

  inject(DestroyRef).onDestroy(() => {
    replaySubject.next(true);
    replaySubject.complete();
  });

  return <T>() => takeUntil<T>(replaySubject.asObservable());
};
